import React from "react";




function Product({ title, onSelected, id }) {
  return (
   
      <div 
        title={title}
        className="pl-3 pr-3 flex py-2 gap-2 hover:shadow cursor-pointer  bg-white relative border rounded"
      >
         <input id={`urun-id${id}`} onChange={e=>onSelected(e)} type="checkbox" value="" className=" cursor-pointer w-5 h-5 text-blue-600 bg-gray-100 border-slate-300 rounded focus:ring-blue-500"/>

        <label htmlFor={`urun-id${id}`} className="flex-fill truncate  cursor-pointer  leading-none">{title}</label>

      </div>
    
  );
}

export default Product;
