import React, { useState } from "react";

function NotFound({onSubmitt, cancel}) {
  const [mode, setMode] = useState(0);

  return (
    <div>
      {mode === 0 ? (
        <div className=" text-center animation-in mt-5">
          <img src="/not-found.png" className="w-[60px] mx-auto block" alt="" />
          <div className="font-medium text-slate-900 mt-4">
            Şirketinize ürünü bulmada yardımcı olalım .
          </div>
          <div className="font- text-slate-700">Lütfen formu doldurunuz.</div>
          <button
            onClick={(e) => setMode(1)}
            className="hover:bg-blue-600 transition bg-blue-500 text-white py-1 px-4 mt-3 rounded font-medium rounded-lg "
          >
            Ürün Talebi Oluştur
          </button>
        </div>
      ) : (
        ""
      )}

      {mode == 1 ? (
        <div className=" sm:w-[400px] mx-auto">
          <form
            action=""
            
            onSubmit={(e) => {
              e.preventDefault();
              onSubmitt(e)
            }}
            className="space-y-3"
          >
            <div className="flex flex-wrap items-center justify-between">
              <div className="font-medium">Ürün Adı * : </div>
              <input
                type="text"
                placeholder="Zorunlu Alan"
                required
                className="w-[170px] md:w-[250px] shadow rounded outline-blue-500 border py-1 px-2"
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="font-medium">KG/Ay Kullanım : </div>
              <input
                type="text"
                placeholder="İsteğe Bağlı Alan"
                className="w-[170px] md:w-[250px] shadow rounded outline-blue-500 border py-1 px-2"
              />
            </div>



            <div className="flex items-center justify-between">
              <div className="font-medium">Menşei : </div>
              <input
                type="text"
                placeholder="İsteğe Bağlı Alan"
                className="w-[170px] md:w-[250px] shadow rounded outline-blue-500 border py-1 px-2"
              />
            </div>


            <div className="flex items-center justify-between">
              <div className="font-medium">Cas Numarası : </div>
              <input
                type="text"
                placeholder="İsteğe Bağlı Alan"
                className="w-[170px] md:w-[250px] shadow rounded outline-blue-500 border py-1 px-2"
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="font-medium">Kullanım Yeri: </div>
              <input
                type="text"
                placeholder="İsteğe Bağlı Alan"
                className="w-[170px] md:w-[250px] shadow rounded outline-blue-500 border py-1 px-2"
              />
            </div>

           


            <div className="flex flex-wrap flex-col-reverse sm:flex-row gap-2 items-center justify-between">
              <button
                type="button"
                onClick={(e) => {
                  setMode(0);
                  cancel()
                }}
                className="hover:bg-slate-500  transition  text-center bg-slate-400 text-slate-800 py-1 px-4 rounded w-full  sm:w-min justify-center  font-medium rounded-lg flex items-center"
              >
                <img src="/xmark.svg" alt="" className="w-[13px] mr-2" /> Kapat
              </button>

              <button
                type="submit"
                className="hover:bg-blue-600 transition text-center bg-blue-500 text-white py-1 px-4 w-full  sm:w-[250px] rounded font-medium rounded-lg "
              >
                Seçtiklerinize ekleyin
              </button>
            </div>
          </form>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default NotFound;
