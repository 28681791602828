import axios from "axios";
import React, { PureComponent, useEffect, useState, useRef } from "react";
import { VList, experimental_VGrid as VGrid } from "virtua";
import "react-virtualized/styles.css";
import Header from "./Header";
import { ViewportList } from "react-viewport-list";
import "../style.css";
import NotFound from "./NotFound";
import Product from "./Product";
import Skeleton from "./Skeleton";
import { useParams, useSearchParams } from "react-router-dom";

export default function Index() {
  const [urunler, setUrunler] = useState(null);
  const [secililer, setSecililer] = useState([]);
  const [data, setData] = useState(null);
  const [gonder, setGonder] = useState(0);

  let [searchParams, setSearchParams] = useSearchParams();

  const inputRef = useRef();

  const { id } = useParams();

  const placeholder = "Lütfen Doldurunuz";

  function bosTablo() {
    axios.get(`/getTanitimUrunler`).then((res) => {
      console.log(res.data);
      setUrunler(res.data);
    });
  }

  useEffect(() => {
   const search = searchParams.get("search");
    axios.get(`/getTanitimUrunler${search ? `?search=${search}` : ""}`).then((res) => {
      setUrunler(res.data);
    });

    axios.get(`/data/${id}`).then((resData) => {
      setData(resData.data);
    });
  
  }, []);

  useEffect(()=>{

 
  },[inputRef])

  function onSearch(e) {
    setUrunler(null);

    if (e.target.value.length >= 3) {
      axios.get(`/getTanitimUrunler?search=${e.target.value}`).then((res) => {
        setUrunler(res.data);
      });
    } else {
      bosTablo();
    }

    /* if (e.target.value == "") {
      axios.get(`/getTanitimUrunler`).then((res) => {
        console.log(res.data);
        setUrunler(res.data);
      });
    } */
  }

  return (
    <div>
      <Header
        inpref={inputRef}
        searchStr={searchParams.get("search")}
        data={data}
        bulunamayanUrunler={(e) => {
          setUrunler([]);
          console.log(urunler);
        }}
        urunler={urunler}
        onSearch={(e) => {
          onSearch(e);
        }}
      />
      <div className="max-w-[1000px] px-3 mx-auto mt-4">
        {secililer.length != 0 ? (
          <div className="animation-in2">
            <div className="flex items-center justify-between">
              <div className="font-medium text-blue-700 whitespace-nowrap md:text-lg">
                Seçtiğiniz Ürünler
              </div>
              <button
                onClick={(e) => setUrunler([])}
                className="hidden px-2 py-1 font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 md:block"
              >
                Bulamadığınız Ürünler için Talep Oluştur
              </button>
            </div>

            <div className="mt-2 mb-5">
              <div className="grid"></div>
              <div className="overflow-x-auto">
                <table className="table w-full">
                  <thead>
                    <tr className="text-blue-700 bg-blue-200 border-b">
                      <th className="px-2 py-1 text-start whitespace-nowrap">
                        Ürün
                      </th>
                      <th className="px-2 py-1 text-start whitespace-nowrap">
                        KG/Ay Kullanım
                      </th>
                      <th className="px-2 py-1 text-start whitespace-nowrap">
                        Menşei
                      </th>
                      <th className="px-2 py-1 text-start whitespace-nowrap">
                        Cas No
                      </th>
                      <th className="px-2 py-1 text-start whitespace-nowrap">
                        Kulanım Yeri
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {secililer.map((val, i) => {
                      return (
                        <tr key={i} className="bg-white border-b animation-in2">
                          <td className="px-2 py-1 border-r">
                            <div className="truncate max-w-[300px]">
                              {val.title}
                            </div>
                          </td>
                          <td
                            onClick={(e) => {
                              const newData = prompt(
                                "Kullanım Yerini Yazınız",
                                val.kullanim
                              );
                              if (!newData) {
                                return;
                              }

                              const data = [...secililer];
                              data[i].kullanim = newData;
                              setSecililer(data);
                            }}
                            className="truncate max-w-[200px] py-1 px-2 border-r"
                          >
                            <div
                              className={
                                val.kullanim == placeholder
                                  ? "text-emerald-600"
                                  : ""
                              }
                            >
                              {val.kullanim}
                            </div>
                          </td>
                          <td
                            onClick={(e) => {
                              const newData = prompt(
                                "Menşei Yazınız",
                                val.mensei
                              );

                              if (!newData) {
                                return;
                              }
                              const data = [...secililer];
                              data[i].mensei = newData;
                              setSecililer(data);
                            }}
                            className="truncate max-w-[200px] py-1 px-2 border-r"
                          >
                            <div
                              className={
                                val.mensei == placeholder
                                  ? "text-emerald-600"
                                  : ""
                              }
                            >
                              {val.mensei}
                            </div>
                          </td>

                          <td
                            onClick={(e) => {
                              const newData = prompt(
                                "Cas No Yazınız",
                                val.cas_no
                              );
                              if (!newData) {
                                return;
                              }
                              const data = [...secililer];
                              data[i].cas_no = newData;
                              setSecililer(data);
                            }}
                            className="truncate max-w-[200px] py-1 px-2 border-r"
                          >
                            <div
                              className={
                                val.cas_no == placeholder
                                  ? "text-emerald-600"
                                  : ""
                              }
                            >
                              {val.cas_no}
                            </div>
                          </td>

                          <td
                            onClick={(e) => {
                              const newData = prompt(
                                "Kullanım Yeri Yazınız",
                                val.kullanim_yeri
                              );
                              if (!newData) {
                                return;
                              }
                              const data = [...secililer];
                              data[i].kullanim_yeri = newData;
                              setSecililer(data);
                            }}
                            className="truncate max-w-[200px] py-1 px-2 border-r"
                          >
                            <div
                              className={
                                val.kullanim_yeri == placeholder
                                  ? "text-emerald-600"
                                  : ""
                              }
                            >
                              {val.kullanim_yeri}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <button
                  onClick={(e) => {
                    console.log(secililer);
                    setGonder(1);
                    axios
                      .post(`/geriGonder`, {
                        secililer: JSON.stringify(secililer),
                        uniq: id,
                      })
                      .then((res) => {
                        console.log(res);
                        setGonder(2);
                        setSecililer([]);
                      })
                      .catch((e) => {
                        console.log(e);
                        setGonder(0);
                        alert("Bir hata oluştu.");
                      });
                  }}
                  disabled={gonder == 1 ? true : false}
                  className="block p-2 px-5 py-2 mx-auto mt-2 font-medium text-white rounded-lg bg-emerald-500 "
                >
                  {gonder == 1 ? (
                    <div class="animate-spin">
                      <img src="/loading.svg" className="w-[18px]" alt="" />
                    </div>
                  ) : (
                    "Seçtiğiniz ürünlerinizi geri şirketimize iletmek için lütfen tıklayınız"
                  )}
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {urunler != null && urunler.length > 0 ? (
          <ViewportList items={urunler}>
            {(item, index) => (
              <div key={index} className="mb-4">
                <div className="mb-1">
                  <div className="flex gap-2 text-lg font-bold text-slate-700">
                    <img src="/bars.svg" className="w-[14px]" alt="" />{" "}
                    {item.name}
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3">
                  {item.urun.map((product, urunKey) => {
                    return (
                      <Product
                        key={urunKey}
                        id={`${index}_${urunKey}`}
                        onSelected={(e) => {
                          console.log(e.target.checked);
                          if (e.target.checked) {
                            setSecililer((current) => [
                              ...current,
                              {
                                title: product,
                                kullanim: placeholder,
                                mensei: placeholder,
                                kullanim_yeri: placeholder,
                                cas_no: placeholder,
                              },
                            ]);
                          } else {
                            setSecililer(
                              secililer.filter((item) => item.title != product)
                            );
                          }
                        }}
                        title={product}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </ViewportList>
        ) : (
          ""
        )}

        {urunler == null ? <Skeleton /> : <></>}

        {urunler != null && urunler.length === 0 ? (
          <NotFound
            cancel={(e) => {
              setUrunler(null);
              bosTablo();
            }}
            onSubmitt={(e) => {
              console.log(e);

              setSecililer((current) => [
                ...current,
                {
                  title: e.target[0].value,
                  kullanim: e.target[1].value,
                  mensei: e.target[2].value,
                  cas_no: e.target[3].value,

                  kullanim_yeri: e.target[4].value,
                },
              ]);

              setTimeout(() => {
                e.target.reset();
              }, 200);
            }}
          />
        ) : (
          <></>
        )}
      </div>

      {gonder == 2 ? (
        <div className="bg-slate-900/30 top-0 left-0 fixed w-full h-full z-[10000]">
          <div className="text-center rounded-t-lg translate-x-[-50%] bg-white max-w-[400px] w-full px-3 py-5 bottom-0 left-[50%] absolute">
            <img
              src="/circle-check.svg"
              className="w-[80px] block mx-auto"
              alt=""
            />
            <div className="text-xl font-medium text-emerald-600">
              Teşekkürler!
            </div>
            <div className="mt-2 text-slate-800">
              İstediğiniz ürün bilgilerini aldık. Size dönüş yapacağız
            </div>

            <div className="flex gap-2 mx-auto mt-3 w-[80%]">
              <a
                href={data ? data.site_url : ""}
                target="_blank"
                className="flex items-center justify-center gap-2 p-2 font-medium rounded whitespace-nowrap bg-slate-200 text-slate-700 flex-fill"
              >
                <img className="w-[17px]" src="/globe.svg" alt="" /> Web Sitemiz
              </a>
              <button
                onClick={(e) => setGonder(0)}
                href="#"
                target="_blank"
                className="flex items-center justify-center gap-2 p-2 font-medium rounded whitespace-nowrap bg-emerald-200 text-emerald-700 flex-fill"
              >
                <img className="w-[17px]" src="/circle-check.svg" alt="" />{" "}
                Tamam
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
